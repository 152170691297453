import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import * as styles from "../styles/home.module.css"

export default function Home({ data }) {
  const laptop = getImage(data.laptopImg)
  const description = data.metadata.siteMetadata.description
  return (
    <Layout title="Software Engineer">
      <section className={styles.header}>
        <div>
          <h2>Create</h2>
          <h3>Develop + Deploy</h3>
          <p>{description}</p>
          <Link className={styles.btn} to="/projects">
            My Projects
          </Link>
        </div>
        <div className={styles.banner}>
          <GatsbyImage image={laptop} alt="Sitting at Laptop" />
        </div>
      </section>
    </Layout>
  )
}
// Create metadata query
export const query = graphql`
  query Home {
    metadata: site {
      siteMetadata {
        description
      }
    }
    laptopImg: file(relativePath: { eq: "sittingAtLaptop.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, PNG])
      }
    }
  }
`
